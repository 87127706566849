/*
 * Based on https://github.com/mhnpd/react-loader-spinner (v5.3.4)
 * see LICENSE in /src/components/loaders/LICENSE for license information
 */

export const DEFAULT_COLOR = '#4fa94d'

export const DEFAULT_WAI_ARIA_ATTRIBUTE = {
  'aria-busy': true,
  role: 'status',
}

export type Style = {
  [key: string]: string
}

export interface BaseProps {
  height?: string | number
  width?: string | number
  color?: string
  ariaLabel?: string
  wrapperStyle?: Style
  wrapperClass?: string
  visible?: boolean
}

export enum LoaderType {
  Puff = 'Puff',
  Oval = 'Oval',
  Rings = 'Rings',
  ThreeDots = 'ThreeDots',
}
