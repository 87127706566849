/*
 * Based on https://github.com/mhnpd/react-loader-spinner (v5.3.4)
 * see LICENSE in /src/components/loaders/LICENSE for license information
 */

import { cn } from '@arcadehq/shared/helpers'
import LogoMobile from 'public/images/mobile-logo.svg'
import { FunctionComponent } from 'react'

import { getDefaultStyle } from './helpers'
import { BaseProps, DEFAULT_WAI_ARIA_ATTRIBUTE } from './type'

interface PuffProps extends BaseProps {
  radius?: string | number
  secondaryColor?: string
}

export const Puff: FunctionComponent<PuffProps> = ({
  ariaLabel = 'puff-loading',
  wrapperStyle,
  wrapperClass,
  visible = true,
}) => (
  <div
    style={{ ...getDefaultStyle(visible), ...wrapperStyle }}
    className={cn('animate-fadeIn', wrapperClass)}
    data-testid='puff-loading'
    aria-label={ariaLabel}
    {...DEFAULT_WAI_ARIA_ATTRIBUTE}
  >
    <div className='flex relative items-center justify-center w-16 h-16'>
      <div className='bg-gray-900/[.04] flex items-center justify-center rounded-full w-16 h-16 z-10 transition-all animate-scale'>
        <LogoMobile className='block w-6 h-6' />
      </div>
      <div className='absolute inset-0 rounded-full z-0 transition-all animate-ping border border-gray-900/10'></div>
    </div>
  </div>
)

export default Puff
