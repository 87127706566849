import { useUser } from 'next-firebase-auth'
import { useMemo } from 'react'
import { useTeamFromDatabase } from 'src/store/team'
import { Team } from 'src/types'

export function useCombinedTeam(
  teamId: string | null,
  teamFromServer: Team | null
): Team | null | undefined {
  // undefined means we're still loading

  const authUser = useUser()
  const { team, loading } = useTeamFromDatabase(teamId)

  return useMemo(() => {
    if (teamId && !teamFromServer && loading) {
      return undefined
    }

    if (!authUser.id || !authUser.email || (!teamFromServer && !team)) {
      return null
    }

    const combinedTeam = {
      ...teamFromServer,
      ...(!loading && team ? team : {}),
    } as Team

    return combinedTeam
  }, [teamId, teamFromServer, loading, authUser.id, authUser.email, team])
}
