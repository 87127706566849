import { useUser } from 'next-firebase-auth'
import { useMemo } from 'react'
import { useUserProfile } from 'src/store/userProfile'
import { UserProfile } from 'src/types'

export function useCombinedUserProfile(
  userProfileFromServer: UserProfile | null
): UserProfile {
  const authUser = useUser()
  const { userProfile, loading } = useUserProfile(authUser)

  return useMemo(() => {
    const combinedUserProfile = {
      ...(userProfileFromServer ? userProfileFromServer : {}),
      ...(!loading && userProfile ? userProfile : {}),
    } as UserProfile

    return combinedUserProfile
  }, [loading, userProfile, userProfileFromServer])
}
