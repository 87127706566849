import './initApp'

import { captureException } from '@sentry/nextjs'
import { ConfigInput, init, User } from 'next-firebase-auth'

import {
  FirebaseAdminConfig,
  FirebaseConfig,
  USE_FIREBASE_EMULATOR,
} from '../constants'
import { isBrowser, isPreviewEnv, isProductionEnv } from './helpers'

export const authPageURL = '/auth'
export const appPageURL = '/flows'

const initConfig: ConfigInput = {
  appPageURL,
  authPageURL,
  cookies: {
    name: 'ArcadeApp',
    httpOnly: true,
    keys: [
      process.env.COOKIE_SECRET_CURRENT,
      process.env.COOKIE_SECRET_PREVIOUS,
    ],
    maxAge: 12 * 60 * 60 * 24 * 1000, // 12 days
    overwrite: true,
    path: '/',
    sameSite: 'strict',
    secure: isProductionEnv() || isPreviewEnv(),
    signed: isProductionEnv() || isPreviewEnv(),
  },
  debug: !!process.env.DEBUG,
  firebaseAuthEmulatorHost: USE_FIREBASE_EMULATOR
    ? // This needs to point to flow-builder and not the actual auth emulator
      // (127.0.0.1:9099) because we proxy from flow-builder to the actual
      // emulator host to circumvent Safari cross-site restrictions.
      '127.0.0.1:3000'
    : undefined,
  firebaseAdminInitConfig: {
    credential: {
      clientEmail: FirebaseAdminConfig?.credential.clientEmail || '',
      privateKey: FirebaseAdminConfig?.credential.privateKey || '',
      projectId: FirebaseAdminConfig?.credential.projectId || '',
    },
    databaseURL: FirebaseAdminConfig?.databaseURL || '',
  },
  firebaseClientInitConfig: FirebaseConfig,
  onTokenRefreshError: error => captureException(error),
  onVerifyTokenError: error => captureException(error),
  useFirebaseAdminDefaultCredential: USE_FIREBASE_EMULATOR,
  tokenChangedHandler,
}

// When not setting a custom `tokenChangeHandler`, this is part of the `initAuth`
// config above, but since we have a custom one (that wraps the default), we need
// to put it outside as `initAuth` doesn't let us pass those config values together
// with a custom `tokenChangeHandler`.
const tokenChangeHandlerConfig = {
  loginAPIEndpoint: '/api/login',
  logoutAPIEndpoint: '/api/logout',
  onLoginRequestError: (error: any) => captureException(error),
  onLogoutRequestError: (error: any) => captureException(error),
}

// Wrapper over the default token change handler that notifies that the auth
// request was completed.
async function tokenChangedHandler(authUser: User) {
  await defaultTokenChangedHandler(authUser)

  // If `authUser.id` is truthy, it was a login call
  if (authUser.id && isBrowser) {
    // Allow other components in the page (particularly `AuthPage` to listen to
    // this event to know that the `loginAPIEndpoint` was called successfully,
    // that the cookies were set, and so that we can now do redirects.
    document.dispatchEvent(new Event('next-firebase-auth:authRequestCompleted'))
  }
}

// Copied from next-firebase-auth@v1.0.0-canary.17.
// See <https://github.com/gladly-team/next-firebase-auth/blob/v1.0.0-canary.17/src/useFirebaseUser.js#L9-L83>.
//
// next-firebase-auth doesn't expose their default handler so in order to wrap
// it, we actually need to copy it
async function defaultTokenChangedHandler(authUser: User) {
  const {
    loginAPIEndpoint,
    logoutAPIEndpoint,
    onLoginRequestError,
    onLogoutRequestError,
  } = tokenChangeHandlerConfig
  let response
  // If the user is authed, call login to set a cookie.
  if (authUser.id) {
    const userToken = await authUser.getIdToken()
    response = await fetch(loginAPIEndpoint!, {
      method: 'POST',
      headers: {
        Authorization: userToken!,
      },
      credentials: 'include',
    })
    if (!response.ok) {
      const responseJSON = await response.json()

      // If the developer provided a handler for login errors,
      // call it and don't throw.
      // https://github.com/gladly-team/next-firebase-auth/issues/367
      const err = new Error(
        `Received ${
          response.status
        } response from login API endpoint: ${JSON.stringify(responseJSON)}`
      )
      if (onLoginRequestError) {
        onLoginRequestError(err)
      } else {
        throw err
      }
    }
  } else {
    // If the user is not authed, call logout to unset the cookie.
    response = await fetch(logoutAPIEndpoint!, {
      method: 'POST',
      credentials: 'include',
    })
    if (!response.ok) {
      const responseJSON = await response.json()

      // If the developer provided a handler for logout errors,
      // call it and don't throw.
      // https://github.com/gladly-team/next-firebase-auth/issues/367
      const err = new Error(
        `Received ${
          response.status
        } response from logout API endpoint: ${JSON.stringify(responseJSON)}`
      )
      if (onLogoutRequestError) {
        onLogoutRequestError(err)
      } else {
        throw err
      }
    }
  }
}

init(initConfig)
