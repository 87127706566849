import hoistNonReactStatics from 'hoist-non-react-statics'
import { useRouter } from 'next/router'
import { AuthAction, PageURL, useUser, withUser } from 'next-firebase-auth'
import { ComponentType, useEffect, useMemo } from 'react'
import { AuthProvider } from 'src/auth/AuthContext'
import { InternalPassedProps } from 'src/auth/withAuthSSR'
import ArcadeError from 'src/components/ArcadeError'
import Hotjar from 'src/components/Hotjar'
import { isPreprodEnv } from 'src/helpers'
import { useClientMemo } from 'src/hooks/useClientMemo'
import { Team, UserProfile, Workspace } from 'src/types'
import { isArcadeDomain } from 'src/utils/customDomains'
import { getFromSerialized } from 'src/utils/serializable'

import { Account } from './Account'
import { shouldRedirectPreprodToProd, shouldRedirectToWelcome } from './helpers'
import { useCombinedTeam } from './useCombinedTeam'
import { useCombinedUser } from './useCombinedUser'
import { useCombinedUserProfile } from './useCombinedUserProfile'
import { useCombinedWorkspace } from './useCombinedWorkspace'

type WithAuthUserOptions = {
  whenAuthed?: AuthAction.RENDER | AuthAction.REDIRECT_TO_APP
  whenAuthedBeforeRedirect?:
    | AuthAction.RENDER
    | AuthAction.SHOW_LOADER
    | AuthAction.RETURN_NULL
  whenUnauthedBeforeInit?:
    | AuthAction.RENDER
    | AuthAction.REDIRECT_TO_LOGIN
    | AuthAction.SHOW_LOADER
    | AuthAction.RETURN_NULL
  whenUnauthedAfterInit?: AuthAction.RENDER | AuthAction.REDIRECT_TO_LOGIN
  appPageURL?: PageURL
  authPageURL?: PageURL
  LoaderComponent?: ComponentType | null
  allowUnverifiedEmail?: boolean
}

/**
 * HOC that extends withAuthUser and loads the User in memory
 * For convenience, we default the next-firebase-auth options to:
 * - whenUnauthed(Before|After)Init: REDIRECT_TO_LOGIN
 */

type T<ComponentProps> = (
  component: ComponentType<ComponentProps>
) => ComponentType<ComponentProps>

export default function withAuth<ComponentProps extends object>(
  options?: WithAuthUserOptions & { allowCustomDomain?: boolean }
): T<ComponentProps> {
  return (ChildComponent: ComponentType<ComponentProps>) => {
    const WithAuthHOC = (props: ComponentProps & InternalPassedProps) => {
      const router = useRouter()
      const authUser = useUser()

      const isAllowedDomain = useClientMemo(
        () =>
          options?.allowCustomDomain ? true : isArcadeDomain(location.hostname),
        true
      )

      const {
        _serializableUserProfile,
        _serializableTeam,
        _serializableWorkspace,
        _algoliaApiKey,
        ...otherProps
      } = props

      const userProfileFromSSR = _serializableUserProfile
        ? getFromSerialized<UserProfile>(_serializableUserProfile)
        : null

      const userProfile = useCombinedUserProfile(userProfileFromSSR)
      const user = useCombinedUser(userProfile)

      const teamFromSSR = _serializableTeam
        ? getFromSerialized<Team>(_serializableTeam)
        : null

      const teamId = (teamFromSSR?.id || user.activeTeamId) ?? null

      const team = useCombinedTeam(teamId, teamFromSSR)
      const workspaceFromSSR = _serializableWorkspace
        ? getFromSerialized<Workspace>(_serializableWorkspace)
        : null

      const workspaceId = workspaceFromSSR?.id ?? null

      const workspace = useCombinedWorkspace(workspaceId, workspaceFromSSR)

      const account = useMemo(
        () => new Account(authUser, userProfile, team, workspace || null),
        [authUser, userProfile, team, workspace]
      )

      useEffect(() => {
        // In preprod, if the user is not an Arcade team member, redirect to the production app
        if (
          shouldRedirectPreprodToProd(isPreprodEnv(), router.pathname, authUser)
        ) {
          account.user.logOut().finally(() => {
            void router.push(
              `https://app.arcade.software/${location.pathname}${location.search}`
            )
          })
        }
      }, [authUser, account, router])

      useEffect(() => {
        if (options?.allowUnverifiedEmail) {
          return
        }

        if (account.isLoggedIn && !account.user.emailVerified) {
          void router.push(
            `/email-verification?redirect=${encodeURIComponent(router.asPath)}`
          )
        }

        if (shouldRedirectToWelcome(account, router.asPath)) {
          void router.push('/welcome')
        }
      }, [account, router])

      // On custom domains, return a 404 for all pages that require auth
      if (!isAllowedDomain) {
        return (
          <ArcadeError
            statusCode={404}
            title='Oops! We dropped the ball.'
            subTitle={''}
          />
        )
      }

      return (
        <AuthProvider
          user={user}
          team={team}
          workspace={workspace}
          account={account}
          algoliaApiKey={_algoliaApiKey || undefined}
        >
          <ChildComponent {...(otherProps as ComponentProps)} />
          {/* Hotjar needs to be instantiated here because it needs useAccount */}
          <Hotjar />
        </AuthProvider>
      )
    }
    WithAuthHOC.displayName = 'WithAuthHOC'
    hoistNonReactStatics(WithAuthHOC, ChildComponent)

    return withUser<ComponentProps>({
      whenUnauthedBeforeInit: AuthAction.RETURN_NULL,
      whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
      ...(options ? options : {}),
    })(WithAuthHOC)
  }
}
