/*
 * Based on https://github.com/mhnpd/react-loader-spinner (v5.3.4)
 * but fixed for Safari and limited to our actual usage
 * see LICENSE in /src/components/loaders/LICENSE for license information
 */

import Oval from './Oval'
import Puff from './Puff'
import ThreeDots from './ThreeDots'

export { Oval, Puff, ThreeDots }
