import { useUser } from 'next-firebase-auth'
import { useMemo } from 'react'
import { useWorkspaceFromDatabase } from 'src/store/workspace'
import { Workspace } from 'src/types'

export function useCombinedWorkspace(
  workspaceId: string | null,
  workspaceFromServer: Workspace | null
): Workspace | null | undefined {
  // undefined means we're still loading

  const authUser = useUser()
  const { workspace, loading } = useWorkspaceFromDatabase(workspaceId)

  return useMemo(() => {
    if (workspaceId && !workspaceFromServer && loading) {
      return undefined
    }

    if (
      !authUser.id ||
      !authUser.email ||
      (!workspaceFromServer && !workspace)
    ) {
      return null
    }

    const combinedWorkspace = {
      ...workspaceFromServer,
      ...(!loading && workspace ? workspace : {}),
    } as Workspace

    return combinedWorkspace
  }, [
    workspaceId,
    workspaceFromServer,
    loading,
    authUser.id,
    authUser.email,
    workspace,
  ])
}
