import Link from 'next/link'

import { Button, ButtonTypes } from './Button'

interface Props {
  statusCode: number
  title: string
  subTitle: string | JSX.Element
}

export default function ArcadeError({ statusCode, title, subTitle }: Props) {
  return (
    <div className='relative flex flex-col overflow-hidden gap-6 text-center px-6 items-center justify-center w-screen h-screen text-sm bg-transparent text-gray-900'>
      <div className='pong'>
        <div className='horizontal'>
          <div className='player1'></div>
          <div className='player2'></div>
          <div className='ball-container'>
            <div className='ball'></div>
          </div>
        </div>
      </div>
      <div className='absolute flex w-full justify-center items-center text-[16rem] sm:text-[24rem] font-bold text-gray-900/[.02] select-none'>
        {statusCode}
      </div>
      <div className='relative flex flex-col items-center justify-center gap-2'>
        <div className='text-base font-semibold'>{title}</div>
        <div className='text-sm text-gray-600'>{subTitle}</div>
      </div>
      <Link href={'/'} passHref legacyBehavior>
        <Button buttonType={ButtonTypes.secondary}>Back to Home</Button>
      </Link>
    </div>
  )
}
