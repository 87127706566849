import { WorkspaceDoc } from '@arcadehq/shared/types'
import { Workspace, WorkspaceData } from 'src/types'

import { useFirebaseEntity } from './firebase'

const COLLECTION_NAME = 'workspaces'

export function getWorkspaceFromDoc(
  data: WorkspaceDoc
): WorkspaceData | undefined {
  return data
}

export function getUpdateData(
  updates: Partial<WorkspaceData>
): Partial<WorkspaceDoc> {
  return updates
}

export function useWorkspaceFromDatabase(id?: string | null) {
  const { entity: workspace, loading } = useFirebaseEntity<
    Workspace,
    WorkspaceData,
    WorkspaceDoc
  >(COLLECTION_NAME, id ?? '', getWorkspaceFromDoc, getUpdateData, false, !id)
  return { workspace, loading }
}
