export function isArcadeDomain(host: string): boolean {
  if (host.endsWith('.arcade.software')) {
    return true
  }

  if (host.endsWith('.vercel.app')) {
    return true
  }

  if (host === 'localhost' || host === '127.0.0.1') {
    return true
  }

  return false
}
