import { DependencyList, useEffect, useRef, useState } from 'react'

/**
 * A hook that allows you to have a memoized value that will not break hydration.
 * The idea is to render something on the server, wait for the hydration to complete and then render the client value.
 * It's useful when you want to render something dependant on the client configuration / local storage / etc.
 *
 *  Example usage
 *
 *    const isBrowserAndReady = useClientMemo(() => true, false)
 *    // `isBrowserAndReady` will be false on the server and true on the client, after the hydration is complete
 *
 *
 *   Example usage with dependency
 *
 *    const displayCamera = useClientMemo(() => step.hasCamera && navigator.accessToCamera, false, [step.hasCamera])
 *    // `displayCamera` will be false on the server and be evaluated on the client after hydration
 */

export function useClientMemo<T>(
  fn: () => T,
  ssrValue: T,
  deps?: DependencyList
): T

export function useClientMemo<T>(
  fn: () => T,
  ssrValue?: T,
  deps: DependencyList = []
): T | undefined {
  const callbackRef = useRef<(() => T) | null>(null)
  callbackRef.current = fn

  const [value, setValue] = useState<T | undefined>(ssrValue)

  useEffect(() => {
    if (callbackRef.current) {
      setValue(callbackRef.current())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return value
}
